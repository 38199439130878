import axios from "axios";

import {addMonths, format, startOfToday} from 'date-fns';
import { useState } from "react";
import {Datepicker, Select} from 'flowbite-react';
import AppointmentForm from "./AppointmentForm";
import { customTheme } from 'components/flowbiteCustomThemes/datepicker'; // Adjust the path as needed

export default () => {
    const [availableTimes, setAvailableTimes] = useState([]);
    const [selectedTime, setSelectedTime] = useState(null);

    async function handleDateChange(selectedDate) {
        selectedDate = format(selectedDate, "yyyy-MM-dd");
        try {
            const response = await axios.get(`https://massageback.kaliflex.nl/api/available-times/${selectedDate}`);
            setAvailableTimes(response.data); // Set available times in state

        } catch (error) {
            console.error('Error fetching available times:', error);
        }
    }

    const handleTimeSelection = (event) => {
        const selectedValue = event.target.value;
        const selectedTime = availableTimes.find((time) => time.time === selectedValue);

        if (selectedTime) {
            const { time_id, date_id } = selectedTime;
            setSelectedTime({ time_id, date_id });
        }
    };

    return (
        <div className="w-full max-w-sm my-10">
            <Datepicker
                id={"datepicker"}
                theme={customTheme}
                open={true}
                showClearButton={false}
                showTodayButton={true}
                onSelectedDateChanged={handleDateChange}
                minDate={new Date(startOfToday())}
                maxDate={new Date(addMonths(startOfToday(), 2))}
                language="nl-NL"
                labelTodayButton="Vandaag"
            />

            <div>
                <div>
                    <Select className={"mt-5 -mb-4"} id="times" required onChange={handleTimeSelection}>
                        <option value="">Selecteer een tijd</option>
                        {availableTimes.map((item, index) => {
                            const timeParts = item.time.split(':'); // Split time string
                            const timeDate = new Date(); // Create a date object
                            timeDate.setHours(parseInt(timeParts[0], 10)); // Set hours
                            timeDate.setMinutes(parseInt(timeParts[1], 10)); // Set minutes

                            return (
                                <option key={index} value={item.time}>
                                    {format(timeDate, 'HH:mm')} {/* Format HH:MM */}
                                </option>
                            );
                        })}
                    </Select>
                </div>
                <AppointmentForm selectedTime={selectedTime} />
            </div>
        </div>
    );
};
