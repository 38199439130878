import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
//import { css } from "styled-components/macro"; //eslint-disable-line
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainLandingPage from "demos/AgencyLandingPage.js";
import ContactUs from "pages/ContactUs.js";

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
            <Route path="/" element={<MainLandingPage />} />
            <Route path="/afspraak-boeken" element={<ContactUs />} />
        </Routes>
      </Router>
    </>
  );
}
