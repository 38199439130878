import React, {useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import css from "styled-components/macro"; //eslint-disable-next-line
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import {PrimaryButton} from "components/misc/Buttons.js";

import SupportIconImage from "../../images/support-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 focus:text-primary-500`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      title: "Aangepaste Massages",
      description: "Van ontspanningsmassages tot sportmassages, wij bieden op maat gemaakte massages die aansluiten bij jouw behoeften en wensen."
    },
    {
      imageSrc: SupportIconImage,
      title: "Ontspanningsmassage",
      description: "Ervaar pure sereniteit terwijl onze masseurs met zachte, vloeiende bewegingen spanningen verlichten en innerlijke rust bevorderen."
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Sportmassage",
      description: "Verbeter je prestaties met onze sportmassage, ontworpen om flexibiliteit te vergroten, spierpijn te verminderen en herstel te versnellen."
    },
    {
      imageSrc: ReliableIconImage,
      title: "Duo massage",
      description: "Deel een moment van rust en welzijn met onze duo massage, afgestemd op de individuele behoeften van jou en je partner of vriend(in)."
    },
    {
      imageSrc: FastIconImage,
      title: "Zwangerschapsmassage",
      description: "Verwen jezelf tijdens de zwangerschap met zachte, veilige technieken die ongemak verminderen en ontspanning bevorderen."
    }
  ];
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading id={"diensten"}>Onze professionele <span tw="text-primary-500">Diensten</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
              <PrimaryButton onClick={toggleModal} tw={"mt-5 mr-0!"}>Meer Info</PrimaryButton>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
      <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
      >
        <CloseModalButton onClick={toggleModal}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        <div className="content">
        </div>
      </StyledModal>
    </Container>
  );
};
