import axios from 'axios';
import tw from "twin.macro";

import { useState, useEffect } from 'react';
import {Select, Textarea, TextInput, Alert} from 'flowbite-react';
import {customTextInputTheme} from "../flowbiteCustomThemes/textinput";
import {customSelectTheme} from "../flowbiteCustomThemes/select";

const PrimaryButton = tw.button`font-bold px-8 lg:px-10 w-full py-3 rounded bg-primary-500 text-gray-100 focus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;

function AppointmentForm({ selectedTime }) {
    const [userDetails, setUserDetails] = useState({
        user_name: '',
        user_email: '',
        appointment_type_id: '',
        comment: '',
        time_id: null,
        date_id: null
    });

    useEffect(() => {
        // Update userDetails whenever selectedTime changes
        if (selectedTime) {
            setUserDetails((prevUserDetails) => ({
                ...prevUserDetails,
                time_id: selectedTime.time_id,
                date_id: selectedTime.date_id,
            }));
        }
    }, [selectedTime]);

    const [appointmentTypes, setAppointmentTypes] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertColor, setAlertColor] = useState('');
    const [alertTitle, setAlertTitle] = useState('');

    useEffect(() => {
        // Fetch appointment types from your backend API
        axios.get('https://massageback.kaliflex.nl/api/appointment-types')
            .then((response) => {
                setAppointmentTypes(response.data);
            })
            .catch((error) => {
                console.error('Error fetching appointment types:', error);
            });
    }, []);

    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
        setUserDetails({
            ...userDetails,
            appointment_type_id: e.target.value,
        });
    };

    const handleInputChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // checks whether user has selected a time
        if (!selectedTime) {
            setAlertColor('warning');
            setAlertTitle('Waarschuwing!');
            setAlertMessage('Je hebt geen tijd gekozen.');
            setShowAlert(true);
            return;
        }
        // Logic to submit userDetails to your backend API for booking
        try {
            await axios.post('https://massageback.kaliflex.nl/api/book-appointment', userDetails);
            setAlertColor('success');
            setAlertTitle('success!');
            setAlertMessage('Je afspraak is geboekt!');
            setShowAlert(true);
            // Add logic for further handling, e.g., displaying success message, redirecting, etc.

        } catch (error) {
            setAlertColor('failure');
            setAlertTitle('fout!');
            setAlertMessage('Er is iets fout gegaan tijdens het boeken, probeer het later opnieuw.');
            setShowAlert(true);
            // Handle error cases, e.g., show error message to the user
        }
    };

    return (
        <>
            <div className="w-full max-w-sm my-10 relative z-[1000]">
                <div className="fixed m-3 top-0 left-0 right-0">
                    {showAlert && (
                        <Alert color={alertColor} withBorderAccent onDismiss={() => setShowAlert(false)}>
                            <span className={'font-medium'}>{alertTitle} </span>{alertMessage}
                        </Alert>
                    )}
                </div>
            </div>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <TextInput
                        theme={customTextInputTheme}
                        id="user_name"
                        type="text"
                        name="user_name"
                        placeholder="Je naam"
                        onChange={handleInputChange}
                        shadow
                        required
                    />
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <TextInput
                        theme={customTextInputTheme}
                        id="user_email"
                        type="email"
                        name="user_email"
                        placeholder="je@email.nl"
                        onChange={handleInputChange}
                        required
                        shadow
                    />
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <Select
                        theme={customSelectTheme}
                        color={"gray"}
                        id="appointmentType"
                        value={selectedType}
                        onChange={handleTypeChange}
                        required>
                        <option value="">
                            Selecteer een afspraak...
                        </option>
                        {appointmentTypes.map((type) => (
                            <option key={type.id} value={type.id}>{type.name}</option>
                        ))}
                    </Select>
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <Textarea
                            id="user_comment"
                            name="comment"
                            rows="4"
                            onChange={handleInputChange}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Heb je nog opmerkingen?">
                    </Textarea>
                </div>
            </div>
           <PrimaryButton type="submit" tw={"w-full!"}>Boek Afspraak</PrimaryButton>
        </form>
        </>
    );
}

export default AppointmentForm;
