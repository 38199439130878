import type { CustomFlowbiteTheme } from 'flowbite-react';

export const customTheme: CustomFlowbiteTheme['Datepicker'] = {
    "root": {
        "base": "relative",
        "input": {
            "field": {
                "base": "relative w-full",
                "icon": {
                    "base": "absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none",
                    "svg": "w-4 h-4 text-white"
                },
                "input": {
                    "base": "bg-[#e35e13] text-white w-full border font-bold justify-center rounded-lg",
                    "colors": "bg-[#e35e13]"
                }
            }
        }
    },
    "popup": {
        "root": {
            "base": "absolute top-10 z-50 block pt-2",
            "inline": "relative bg-orange-500 top-0 z-auto",
            "inner": "inline-block rounded-lg bg-white p-4 shadow-lg dark:bg-gray-700"
        },
        "header": {
            "base": "",
            "title": "px-2 py-3 text-center font-semibold text-gray-900 dark:text-white",
            "selectors": {
                "base": "flex justify-between mb-2",
                "button": {
                    "base": "text-sm rounded-lg text-gray-900 dark:text-white bg-white dark:bg-gray-700 font-semibold py-2.5 px-5 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-200 view-switch",
                    "prev": "",
                    "next": "",
                    "view": ""
                }
            }
        },
        "view": {
            "base": "p-1"
        },

        "footer": {
            "base": "flex mt-2 space-x-2",
            "button": {
                "base": "w-full rounded-lg px-5 py-2 text-center text-sm font-medium focus:ring-4 focus:ring-cyan-300",
                "today": "hover:bg-[#e35e13] text-white",
                "clear": "border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
            }
        }
    },
}