import React from "react";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import ContactUsBigForm from "../components/forms/TwoColContactUsWithIllustrationFullForm";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import useAnimatedNavToggler from "helpers/useAnimatedNavToggler.js";

import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import klantenImage from "images/klanten.webp";
import KlantManImage from "images/Klant_man.webp";
export default () => {
    const  { toggleNavbar } = useAnimatedNavToggler();
    return (
      <AnimationRevealPage onClick={toggleNavbar} disabled={true}>
        <Hero />
        <MainFeature />
        <Features />
        <MainFeature2 />
        <Testimonial
          description="Lees wat onze geweldige klanten zeggen over hun ervaringen in onze massagesalon. Bij ons staat klantcontact centraal. We streven ernaar om elke massage tot een persoonlijke reis van ontspanning te maken. Ontdek hoe ons toegewijde team een wereld van rust en welzijn creëert voor onze gewaardeerde klanten."
          testimonials={[
            {
              imageSrc:
                klantenImage,
              quote:
                "In deze massagesalon vind ik niet alleen verlichting voor mijn spieren, maar ook voor mijn ziel. Elke aanraking lijkt een verhaal van ontspanning te vertellen. Mijn lichaam zegt dankjewel, mijn geest zegt bedankt, en mijn ziel fluistert: hier ben ik thuis.",
            },
            {
              imageSrc:
                KlantManImage,
              quote:
                "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
            }
          ]}
          textOnLeft={true}
        />
        <FAQ
          imageSrc={customerSupportIllustrationSrc}
          imageContain={true}
          imageShadow={false}
        />
        <ContactUsBigForm />
        <Footer />
      </AnimationRevealPage>
    );
};
